import { Component } from "react";
import "./ProfileLink.css";

class ProfileLink extends Component {
  constructor(props) {
    super(props);
    this.state = { image: props.image, title: props.title, link: props.link };
  }
  render() {
    return (
      <div className="profile-link">
        <img
          className="profile-link-image"
          src={this.state.image}
          alt=""
        />
        <a href={this.state.link} className="profile-link-link">
          {this.state.title}
        </a>
      </div>
    );
  }
}

export default ProfileLink;
