import "./LeftBarList.css";
import { Component } from "react";

class LeftBarList extends Component {
  constructor(props) {
    super(props);
    this.state = { title: props.title, details: props.details };
  }

  render() {
    return (
      <div className="left-bar-list">
        <p className="left-bar-title">{this.state.title}</p>
        <ul className="left-bar-list-list">
          {this.state.details.map((detail) => (
            <li className="left-bar-list-list-item">{detail}</li>
          ))}
        </ul>
      </div>
    );
  }
}

export default LeftBarList;
