import { Component } from "react";
import "./AboutMe.css";
import "./AboutMeMobile.css";
import noursandid from "./img/noursandid.jpg";
class AboutMe extends Component {
  constructor(props) {
    super();
    this.state = {
      isMobile: props.isMobile,
    };
  }

  render() {
    if (this.state.isMobile()) {
      return (
        <div className="about-me-mobile">
          <div className="about-me-image-box-mobile">
            <img
              className="about-me-image-mobile"
              src={noursandid}
              alt="Nour Sandid"
            />
          </div>

          <div className="about-me-info-mobile">
            <p className="about-me-info-title-mobile">Hello! 👋</p>
            <p className="about-me-info-details-mobile">
              My name is Nour Sandid, and I'm a principal iOS engineer, currently
              working at Backbase Amsterdam.
              <br />
              <br />
              My first software was something like <i>Windows Media Player</i>,
              I developed it for personal use when I was 12 years old, and kept
              using and enhancing it for 3 years, and of course some other
              softwares on the side just to fulfil other needs. Now I'm working
              on banking apps that everyone uses.
              <br />
              <br />I am addicted to solving problems and trying to find the
              optimal solution with best practices, but you know what they say{" "}
              <i>"first make it work then make it better".</i>
              <br />
              <br />
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="about-me home-section">
          <img className="about-me-image" src={noursandid} alt="Nour Sandid" />
          <div className="about-me-info">
            <p className="about-me-info-title">Hello! 👋</p>
            <p className="about-me-info-details">
              My name is Nour Sandid, and I'm a principal iOS engineer, currently
              working at Backbase Amsterdam.
              <br />
              <br />
              My first software was something like <i>Windows Media Player</i>,
              I developed it for personal use when I was 12 years old, and kept
              using and enhancing it for 3 years, and of course some other
              softwares on the side just to fulfil other needs. Now I'm working
              on banking apps that everyone uses.
              <br />
              <br />I am addicted to solving problems and trying to find the
              optimal solution with best practices, but you know what they say{" "}
              <i>"first make it work then make it better".</i>
              
              <br />
              <br />
            </p>
          </div>
        </div>
      );
    }
  }
}

export default AboutMe;
