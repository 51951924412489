import { Component } from "react";
import "./Intro.css";
import "./IntroMobile.css";
import memoji from "./img/memoji.png";
import logo from "./img/logo-small.png";
import cv from "./img/cv.png";
import courses from "./img/logo-small.png";

class Intro extends Component {
  constructor(props) {
    super();
    this.state = {
        isMobile: props.isMobile
    };
  }

    render() {
    if (this.state.isMobile()) {
      return ( 
        <div className="intro-mobile home-section">
          <div className="intro-memoji-mobile">
            <img className="intro-image-mobile" src={memoji} alt="memoji" />
          </div>
          <div className="intro-info-mobile">
            <img className="intro-logo-mobile" src={logo} alt="logo" />
            <div className="intro-details-mobile">
              <p className="intro-title-mobile">Nour Sandid</p>
              <p className="intro-subtitle-mobile">iOS Engineer </p>
            </div>
            <div className="intro-buttons-mobile">
              <div className="intro-button-mobile">
                <a href="https://cv.noursandid.com">
                  <img className="intro-button-icon-mobile" alt="cv" src={cv} />
                  <p className="intro-button-text-mobile">CV</p>
                </a>
              </div>
              <div className="intro-button-mobile">
                <a href="https://courses.noursandid.com">
                  <img className="intro-button-icon-mobile" alt="courses" src={courses} />
                  <p className="intro-button-text-mobile">Courses</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="intro">
          <div className="intro-info">
            <img className="intro-logo" src={logo} alt="logo" />
            <div className="intro-details">
              <p className="intro-title">Nour Sandid</p>
              <p className="intro-subtitle">iOS Engineer </p>
            </div>
            <div className="intro-buttons">
              <div className="intro-button">
                <a href="https://cv.noursandid.com">
                  <img className="intro-button-icon" alt="cv" src={cv} />
                  <p className="intro-button-text">CV</p>
                </a>
              </div>
              <div className="intro-button">
                <a href="https://courses.noursandid.com">
                  <img className="intro-button-icon" alt="courses" src={courses} />
                  <p className="intro-button-text">Courses</p>
                </a>
              </div>
            </div>
          </div>
          <div className="intro-memoji">
            <img className="intro-image" src={memoji} alt="memoji" />
          </div>
        </div>
      );
    }
  }
}

export default Intro;
