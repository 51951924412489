import { Component } from "react";
import "./PersonalDetails.css";
import phone from "./img/phone.png";
import email from "./img/email.png";
import github from "./img/github.png";
import linkedin from "./img/linkedin.png";
import ProfileLink from "./ProfileLink/ProfileLink";

class PersonalDetails extends Component {
  render() {
    return (
      <div className="personal-details">
        <p className="left-bar-title">Personal Details</p>
        <ProfileLink
          image={phone}
          title="+31 659363915"
          link="tel: +31659363915"
        />
        <ProfileLink
          image={email}
          title="noursandid@gmail.com"
          link="mailto: noursandid@gmail.com"
            />
            <ProfileLink
          image={github}
          title="https://github.com/noursandid"
          link="https://github.com/noursandid"
            />
            <ProfileLink
          image={linkedin}
          title="https://linkedin.com/in/noursandid"
          link="https://linkedin.com/in/noursandid"
        />
      </div>
    );
  }
}

export default PersonalDetails;
