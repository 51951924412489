import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import CV from "./CV/CV";
import Main from "./Main/Main";

ReactDOM.render(
  <Router>
    <Routes>
      <Route exact path="/" element={<Main />} />
      <Route path="/cv" element={<CV />} />
    </Routes>
  </Router>,
  document.getElementById("root")
);
reportWebVitals();
