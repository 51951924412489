import { Component } from "react";
import "./ContactMe.css";
import "./ContactMeMobile.css";
import github from "./img/github.png";
import iPhone from "./img/iphone.png";
import email from "./img/email.png";
import linkedin from "./img/linkedin.png";
import location from "./img/location.png";

class ContactMe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: props.isMobile,
    };
  }

  render() {
    if (this.state.isMobile()) {
      return (
        <div className="contact-me-mobile">
          <div className="contact-me-box-mobile">
            <div className="contact-me-title-box-mobile">
              <p className="contact-me-title-mobile">Get in Touch</p>
            </div>
            <div className="contact-me-methods-box-mobile">
            {this.contactMethod.map((method) => (
              <div className="contact-me-method-mobile" key={method.title}>
                <img
                  src={method.image}
                  alt={method.title}
                  className="contact-me-method-icon-mobile"
                />
                <a className="contact-me-method-title-mobile" href={method.link}>
                  {method.title}
                </a>
              </div>
            ))}
            </div>
            
          </div>
        </div>
      );
    } else {
      return (
        <div className="contact-me home-section">
          <div className="contact-me-box">
            {this.contactMethod.map((method) => (
              <div className="contact-me-method" key={method.title}>
                <img
                  src={method.image}
                  alt={method.title}
                  className="contact-me-method-icon"
                />
                <a className="contact-me-method-title" href={method.link}>
                  {method.title}
                </a>
              </div>
            ))}
          </div>
        </div>
      );
    }
  }

  contactMethod = [
    {
      image: github,
      title: "github.com/noursandid",
      link: "https://github.com/noursandid",
    },
    { image: iPhone, title: "+31 659363915", link: "tel: +31659363915" },
    {
      image: email,
      title: "noursandid@gmail.com",
      link: "mailto: noursandid@gmail.com",
    },
    {
      image: linkedin,
      title: "linkedin.com/in/noursandid",
      link: "https://linkedin.com/in/noursandid",
    },
    {
      image: location,
      title: "Amsterdam, Netherlands",
      link: "https://goo.gl/maps/7XVzohtc2edxcYdSA",
    },
  ];
}

export default ContactMe;
