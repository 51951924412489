import { Component } from "react";
import Experience from "./Experience/Experience";
import AboutMe from "./AboutMe/AboutMe";
import Intro from "./Intro/Intro";
import "./Main.css";
import ContactMe from "./ContactMe/ContactMe";
import GithubProjects from "./GithubProjects/GithubProjects";

class Main extends Component {
  constructor() {
    super();
    this.state = {
      isMobile: () => {
        return window.innerWidth <= 500;
      },
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({
      isMobile: () => {
        return window.innerWidth <= 500;
      },
    });
  };

  render() {
    this.pages = 5;
    return (
      <div className={`main`}>
        <Intro isMobile={this.state.isMobile} />
        <AboutMe isMobile={this.state.isMobile} />
        <Experience isMobile={this.state.isMobile} />
        <GithubProjects isMobile={this.state.isMobile} />
        <ContactMe isMobile={this.state.isMobile} />
      </div>
    );
  }
}

export default Main;
