import { Component } from "react";
import "./Experience.css";

class Experience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: props.logo,
        title: props.title,
      link: props.link,
      position: props.position,
      date: props.date,
      details: props.details,
    };
  }

  render() {
    return (
      <div className="experience">
        <div className="experience-header">
          <img className="experience-logo" src={this.state.logo} alt="" />
          <a href={this.state.link} className="experience-title">{this.state.title}</a>
        </div>
        <div className="experience-subtitle">
          <p className="experience-position">{this.state.position}</p>
          <p className="experience-date">{this.state.date}</p>
        </div>
        <ul className="experience-list">
          {this.state.details.map((detail) => (
            <li className="experience-list-item">{detail}</li>
          ))}
        </ul>
      </div>
    );
  }
}

export default Experience;
