import { Component } from "react";
import "./GithubProjects.css";
import "./GithubProjectsMobile.css";

class GithubProjects extends Component {
  constructor(props) {
    super();
    this.state = {
      isMobile: props.isMobile,
    };
  }

  projects = [
    {
      title: "SundeedQLite",
      description: "The easiest local storage library in Swift. Features include Core Data integration, SQLite support, and comprehensive data type handling.",
      link: "https://github.com/noursandid/SundeedQLite",
      stars: 17,
      tags: ["Swift", "iOS", "Database", "SQLite"]
    },
    {
      title: "SundeedSpotlight",
      description: "An easily implemented spotlight library to walk users through app features with customizable UI and interactive elements.",
      link: "https://github.com/noursandid/SundeedSpotlight",
      stars: 7,
      tags: ["Swift", "iOS", "UI", "Onboarding"]
    },
    {
      title: "SundeedDatePicker",
      description: "A fully customizable DatePicker for iOS that allows separate date component selection with custom formatting.",
      link: "https://github.com/noursandid/SundeedDatePicker",
      stars: 4,
      tags: ["Swift", "iOS", "UI", "DatePicker"]
    },
    {
      title: "PageControl",
      description: "An interactive Page Control component for iOS with customizable dots and smooth animations.",
      link: "https://github.com/noursandid/PageControl",
      stars: 6,
      tags: ["Swift", "iOS", "UI", "Controls"]
    },
    {
      title: "MaterialTextField",
      description: "A Material Design inspired text field implementation for iOS with floating labels and error states.",
      link: "https://github.com/noursandid/MaterialTextField",
      stars: 3,
      tags: ["Swift", "iOS", "UI", "Material Design"]
    }
  ];

  renderProject(project, isMobile) {
    const className = isMobile ? "mobile" : "";
    return (
      <div className={`github-project${className ? '-' + className : ''}`} key={project.title}>
        <div className={`github-project-content${className ? '-' + className : ''}`}>
          <h3 className={`github-project-title${className ? '-' + className : ''}`}>
            {project.title}
          </h3>
          <p className={`github-project-description${className ? '-' + className : ''}`}>
            {project.description}
          </p>
          <div className={`github-project-tags${className ? '-' + className : ''}`}>
            {project.tags.map(tag => (
              <span key={tag} className={`github-project-tag${className ? '-' + className : ''}`}>
                {tag}
              </span>
            ))}
          </div>
          <div className={`github-project-stats${className ? '-' + className : ''}`}>
            <span className={`github-project-stars${className ? '-' + className : ''}`}>
              ★ {project.stars}
            </span>
          </div>
          <a
            href={project.link}
            target="_blank"
            rel="noopener noreferrer"
            className={`github-project-link${className ? '-' + className : ''}`}
          >
            View Project
          </a>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.isMobile()) {
      return (
        <div className="github-projects-mobile">
          <h2 className="github-projects-title-mobile">Open Source Projects</h2>
          <div className="github-projects-container-mobile">
            {this.projects.map(project => this.renderProject(project, true))}
          </div>
        </div>
      );
    }

    return (
      <div className="github-projects home-section">
        <h2 className="github-projects-title">Open Source Projects</h2>
        <div className="github-projects-container">
          {this.projects.map(project => this.renderProject(project, false))}
        </div>
      </div>
    );
  }
}

export default GithubProjects;
