import { Component } from "react";
import "./Experience.css";
import "./ExperienceMobile.css";

class Experience extends Component {
  constructor(props) {
    super();
    this.state = {
      isMobile: props.isMobile,
      selectedExperience: this.experiences[0],
    };
  }

  selectExperience = (experience) => {
    this.setState({ selectedExperience: experience });
  };

  render() {
    if (this.state.isMobile()) {
      return (
        <div className="main-experience-mobile">
          <div className="main-experience-box-mobile">
            <div className="main-experience-banner-mobile">
              {this.experiences.map((experience) => (
                <div
                  className={`main-experience-banner-company-name-mobile ${
                    experience.title === this.state.selectedExperience.title &&
                    experience.position === this.state.selectedExperience.position
                      ? "active"
                      : ""
                  }`}
                  onClick={() => this.selectExperience(experience)}
                  key={`${experience.title} ${experience.position}`}
                >
                  <p className="main-experience-banner-company-name-title-mobile">
                    {experience.title}
                  </p>
                </div>
              ))}
            </div>
            <div className="main-experience-banner-info-mobile">
              <div className="main-experience-banner-info-position-mobile">
                <p className="main-experience-banner-info-position-title-mobile">
                  {this.state.selectedExperience.position}
                </p>
              </div>
              <ul className="main-experience-banner-info-list-mobile">
                {this.state.selectedExperience.info.map((info) => (
                  <li
                    className="main-experience-banner-info-detail-mobile"
                    key={info}
                  >
                    {info}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="main-experience home-section">
          <div className="main-experience-box">
            <div className="main-experience-banner">
              {this.experiences.map((experience) => (
                <div
                  className={`main-experience-banner-company-name ${
                    experience.title === this.state.selectedExperience.title &&
                    experience.position === this.state.selectedExperience.position
                      ? "active"
                      : ""
                  }`}
                  onClick={() => this.selectExperience(experience)}
                  key={`${experience.title} ${experience.position}`}
                >
                  <p className="main-experience-banner-company-name-title">
                    {experience.title}
                  </p>
                </div>
              ))}
            </div>
            <div className="main-experience-banner-info">
              <div className="main-experience-banner-info-position">
                <p className="main-experience-banner-info-position-title">
                  {this.state.selectedExperience.position}
                </p>
              </div>
              <ul className="main-experience-banner-info-list">
                {this.state.selectedExperience.info.map((info) => (
                  <li
                    className="main-experience-banner-info-detail"
                    key={info}
                  >
                    {info}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      );
    }
  }

  experiences = [
    {
      title: "Backbase",
      position: "Principal iOS Engineer",
      info: [
        "Merged multiple github repositories into one mono-repo",
        "Provided technical direction and oversight for multiple iOS engineering teams",
        "Coordinated between product and engineering teams to align priorities",
        "Planned and executed multiple cross teams initiatives",
      ],
    },
    {
      title: "Backbase",
      position: "Senior iOS Engineer",
      info: [
        "Automated release process to insure fast and reliable releases using Bitrise steps, bash scripts and Fastlane",
        "Migrated from Jenkins to Bitrise",
        "Implemented XCodeGen to generate our projects",
      ],
    },
    {
      title: "Eurisko Mobility",
      position: "Senior iOS Consultant",
      info: [
        "Insured code quality and clean code with the help of SonarQube and code review on Azure",
        "Implemented Clean Swift architecture to deliver a high code quality and maintainability",
        "Installed SwiftGen to generate images, colors, and localizable strings, and SwiftLint to keep the code clean",
        "Developed custom-made theming layer, to automatically adapt multiple layouts",
        "Developed custom, highly reusable, components to be used by all squads",
      ],
    },
    {
      title: "TF961",
      position: "Senior iOS Consultant",
      info: [
        "Followed Agile Methodology, conducting daily standup meetings, and planning and design grooming sessions",
        "Implemented MVVM and VIPER architecture to develop the application",
        "Implemented RxSwift to bind between the ViewModel and the View",
        "Launched application in less than 4 months, while making sure code quality was not sacrificed with the help of SonarQube and code review in bitbucket",
        "Developed major components to be used across the application with smooth animations and transitions",
      ],
    },
    {
      title: "Primeware (An ITG company)",
      position: "Oracle Product Consultant",
      info: [
        "Developed solutions for clients in Telecommunication, banking, money transfer and governmental fields",
        "Traveled abroad to work onsite to deliver the highest quality",
        "Led the team and distributed tasks to meet tight and critical deadlines",
        "Developed a custom solution to communicate with legacy systems (BSCS using CORBA) and ensure to handle 250 transactions per second",
      ],
    },
    {
      title: "Technology SARL",
      position: "iOS Developer",
      info: [
        "Implemented Core Data to save data locally",
        "Created highly reusable components to be used in multiple applications",
        "Integrated with backend using Rest Services",
        "Developed backend server using NodeJS language",
        "Created socket servers to handle chatting and location distribution for clients",
      ],
    },
    {
      title: "Torch Academy",
      position: "iOS Tutor",
      info: [
        "Helped fresh graduated student develop ideas",
        "Assisted entrepreneurs build minimum viable products (MVP)",
        "Transferred best practices knowledge to students",
      ],
    },
    {
      title: "Fusion Second",
      position: "iOS Developer Intern",
      info: [
        "Helped enhancing old apps",
        "Created new interfaces to implement",
        "Researched new technologies and ideas",
      ],
    },
  ];
}

export default Experience;
