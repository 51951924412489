import { Component } from "react";
import "./CV.css";
import profilePic from "./profile-pic.png";
import PersonalDetails from "./PersonalDetail/PersonalDetails";
import ProfessionalProfile from "./ProfessionalProfile/ProfessionalProfile";
import Experience from "./Experience/Experience";
import backbaseImage from "./img/backbase.webp";
import euriskoMobilityImage from "./img/euriskoMobility.jpg";
import tf961Image from "./img/tf961.jpeg";
import primwareImage from "./img/primware.jpeg";
import technologySARLImage from "./img/technologySARL.png";
import torchAcademyImage from "./img/torchAcademy.png";
import fusionSecondImage from "./img/fusionSecond.png";
import downloadIcon from "./img/download.png";
import Education from "./Eduaction/Education";
import LeftBarList from "./LeftBarList/LeftBarList";

class CV extends Component {
  render() {
    return (
      <div className="cv">
        <div className="left-bar">
          <p className="name">Nour Sandid</p>
          <p className="title">Senior iOS Engineer</p>
          <img className="profile-pic" src={profilePic} alt="Profile" />
          <div className="left-bar-download-box">
            <img className="left-bar-download-box-icon" alt="download" src={downloadIcon} />
            <a className="left-bar-download-box-button" href="/Nour%20Sandid.pdf" download>Download</a>
          </div>
          <PersonalDetails />
          <ProfessionalProfile details="Senior iOS Consultant with 6+ years of experience in working in the information technology and services industry. Worked on projects in Lebanon, Iraq, Kuwait, and UAE." />
          <Education />
          <LeftBarList
            title="Programming Languages"
            details={["Swift", " Objective C", "Java", "JavaScript"]}
          />
          <LeftBarList
            title="Technologies"
            details={[
              "XCode",
              "Oracle SOA Suite 12c, Weblogic",
              "Docker",
              "Linux",
              "Jenkins, Bitrise, Github Actions",
              "Fastlane",
              "XCodeGen",
              "SwiftGen",
              "RxSwift",
              "Bitbucket, Stash, Github",
            ]}
          />
          <LeftBarList
            title="Architectures"
            details={["MVC", "MVVM", "VIPER", "VIP"]}
          />
        </div>
        <div className="experiences">
          <p className="experiences-title">Professional Experience</p>
          <Experience
            logo={backbaseImage}
            title="Backbase"
            link="https://backbase.com"
            position="Senior iOS Engineer"
            date="September 2021 - Present"
            details={[
              "Automated release process to insure fast and reliable releases using Bitrise steps, bash scripts and fastlane",
              "Migrated from Jenkins to Bitrise",
              "implemented XCodeGen to generate our projects",
              "Migrating from Bitrise to Github Actions"
            ]}
          />
          <Experience
            logo={euriskoMobilityImage}
            title="Eurisko Mobility"
            link="https://euriskomobility.com"
            position="Senior iOS Consultant"
            date="February 2021 - September 2021"
            details={[
              "Insured code quality and clean code with the help of SonarQube and code review on Azure",
              "Implemented Clean Swift architecture to deliver a high code quality and maintainability",
              "Installed SwiftGen to generate images, colors, and localizable strings, and SwiftLint to keep the code clean",
              "Developed custom-made theming layer, to automatically adapt multiple layouts",
              "Developed custom, highly reusable, components to be used by all squads",
            ]}
          />
          <Experience
            logo={tf961Image}
            title="TF961"
            link="https://tf961.com"
            position="Senior iOS Consultant"
            date="April 2019 – February 2021"
            details={[
              "Followed Agile Methodology, conducting daily standup meetings, and planning and design grooming sessions",
              "Implemented MVVM and VIPER architecture to develop the application",
              "Implemented RxSwift to bind between the ViewModel and the View",
              "Launched application in less than 4 months, while making sure code quality was not sacrificed with the help of SonarQube and code review in bitbucket",
              "Developed major components to be used across the application with smooth animations and transitions",
            ]}
          />
          <Experience
            logo={primwareImage}
            title="Primware (An ITG Company)"
            link="https://www.itgholding.com/affiliate/16/primeware"
            position="Oracle Product Consultant"
            date="March 2017 – April 2019"
            details={[
              "Developed solutions for clients in Telecommunication, banking, money transfer and governmental fields",
              "Traveled abroad to work onsite to deliver the highest quality",
              "Led the team and distributed tasks to meet tight and critical deadlines",
              "Developed a custom solution to communicate with legacy systems (BSCS using CORBA) and ensure to handle 250 transactions per second",
            ]}
          />
          <Experience
            logo={technologySARLImage}
            title="Technology SARL"
            link="http://technologysarl.com"
            position="iOS Developer"
            date="February 2016 – February 2017"
            details={[
              "Implemented Core Data to save data locally",
              "Created highly reusable components to be used in multiple applications",
              "Integrated with backend using Rest Services",
              "Developed backend server using NodeJS language",
              "Created socket servers to handle chatting and location distribution for clients",
            ]}
          />
          <Experience
            logo={torchAcademyImage}
            title="Torch Academy"
            link="http://technologysarl.com"
            position="iOS Tutor"
            date="February 2016 – February 2017"
            details={[
              "Helped fresh graduated student develop ideas",
              "Assisted entrepreneurs build minimum viable products (MVP)",
              "Transferred best practices knowledge to students",
            ]}
          />
          <Experience
            logo={fusionSecondImage}
            title="Fusion Second"
            link="https://fusionsecond.com"
            position="iOS Developer Intern"
            date="June 2015 – August 2015"
            details={[
              "Helped enhancing old apps",
              "Created new interfaces to implement",
              "Researched new technologies and ideas",
            ]}
          />
        </div>
      </div>
    );
  }
}

export default CV;
