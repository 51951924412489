import { Component } from "react";
import "./ProfessionalProfile.css";

class ProfessionalProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {details: props.details }
    }
  render() {
    return (
      <div className="professional-profile">
            <p className="professional-profile-title left-bar-title">Professional Profile</p>
            <p className="professional-profile-text">{ this.state.details }</p>
      </div>
    );
  }
}

export default ProfessionalProfile;
