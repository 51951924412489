import { Component } from "react";
import "./Education.css"

class Education extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="education">
                <p className="left-bar-title">Education</p>
                <p className="education-school-name">Lebanese American University</p>
                <p className="education-date">2011-2015</p>
                <p className="education-school-major">Bachelor of Science, Computer Sciences</p>
            </div>
        )
    }
}

export default Education;